import { Button, CircularProgress, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import useMessageForm from "../../hooks/useMessageForm";
import styles from "./message.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

const IntegratedMessage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { control, onFormSubmit, errors, uploaded, uploading } =
    useMessageForm(setDialogOpen);

  return (
    <form className={styles.form_container} onSubmit={onFormSubmit}>
      <div className={styles.form_inner_div}>
        <div className={styles.form_message_container}>
          <Controller
            fullWidth
            name="message"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  label="留言祝福*"
                  placeholder="请留下您的祝福"
                  variant="outlined"
                  fullWidth
                  rows={10}
                  multiline
                  {...field}
                  error={!!errors?.message}
                />
              );
            }}
          />
        </div>
        <div className={styles.form_name_container}>
          <div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="来宾姓名*"
                    variant="standard"
                    placeholder="请留下您的名字"
                    {...field}
                    error={!!errors?.name}
                  />
                );
              }}
            />
          </div>
          <Button
            disabled={uploaded || uploading}
            variant="outlined"
            type="submit"
            color="error"
          >
            {uploading ? (
              <CircularProgress style={{ width: "24px", height: "24.5px" }} />
            ) : uploaded ? (
              "完成"
            ) : (
              "提交"
            )}
          </Button>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">网络似乎有点问题...</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              您也可以使用电子邮件进行发送，
            </div>
            <div>
              根据微信的限制，请点击右上角的···使用自带浏览器打开此邀请函再进行发送
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>关闭</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default IntegratedMessage;
