import { useEffect, useRef } from "react";
import styles from "./map.module.scss";
import AMapLoader from "@amap/amap-jsapi-loader";

const markerContent = `<div class=${styles.marker}>
<img src="https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png">
</div>`;

export default function MapContainer() {
  let map = useRef();
  let marker = useRef();

  useEffect(() => {
    AMapLoader.load({
      key: "	efcb785d29aa3404d8c0014427f4a2fa", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
      .then((AMap) => {
        map.current = new AMap.Map("map_container", {
          // 设置地图容器id
          viewMode: "3D", // 是否为3D地图模式
          zoom: 14, // 初始化地图级别
          center: [121.458322, 31.29416], // 初始化地图中心点位置,
          // marker:
        });

        const position = new AMap.LngLat(121.458322, 31.29416); //Marker 经纬度
        marker.current = new AMap.Marker({
          position: position,
          content: markerContent, //将 html 传给 content
          offset: new AMap.Pixel(-13, -30), //以 icon 的 [center bottom] 为原点,
        });

        marker.current.on("click", () => {
          window.open("https://surl.amap.com/fU1ATry1fc3e", "_blank");
        });

        map.current.add(marker.current);

        return AMap;
      })
      .catch((e) => {
        console.log(e);
      })
      .then((AMap) => {
        AMap.plugin("AMap.ToolBar", function () {
          var toolbar = new AMap.ToolBar(); //缩放工具条实例化
          map.current.addControl(toolbar);
        });
      });

    return () => {
      map?.current?.destroy();
    };
  }, []);

  return (
    <div
      id="map_container"
      className={styles.map_container}
      style={{ height: "200px" }}
    ></div>
  );
}
