import "@fontsource/noto-sans"; // Defaults to weight 400
import "@fontsource/noto-sans/500.css"; // Specify weight
import "@fontsource/noto-sans/400-italic.css"; // Specify weight and style

import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/app";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </React.StrictMode>
);
