import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { messageService } from "../services/messageServices";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { isInWechat } from "../utils";
import copy from "copy-to-clipboard";

function useMessageForm(setDialogOpen) {
  const { enqueueSnackbar } = useSnackbar();

  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const isInWechatFlag = useMemo(() => isInWechat(), []);

  const methods = useForm({
    criteriaMode: "all",
    defaultValues: {
      name: "",
      message: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
  } = methods;

  const { name, message } = watch();

  const onFormSubmit = handleSubmit(() => {
    if (networkError) setNetworkError(false);

    if (name.length <= 0 || message.length <= 0) {
      if (name.length <= 0) {
        setError("name", { message: "请留下您的名字" });
        enqueueSnackbar("请留下您的名字。", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      if (message.length <= 0) {
        setError("message", "请留下您的祝福");
        enqueueSnackbar("请留下您的祝福。", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      return;
    } else {
      setUploading(true);
      messageService(name, message)
        .then((res) => {
          if (res.status !== 200) {
            setNetworkError(true);
          } else {
            setUploaded(true);
          }
        })
        .catch(() => {
          setNetworkError(true);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  });

  useEffect(() => {
    if (networkError) {
      if (isInWechatFlag) {
        setDialogOpen(true);
        if (navigator?.clipboard?.writeText) {
          navigator.clipboard.writeText(message);
        } else {
          copy(message);
        }
      } else {
        enqueueSnackbar("网络似乎有点问题。", {
          variant: "error",
          autoHideDuration: 4000,
          action: () => (
            <Button
              onClick={() =>
                window.open(
                  `mailto:wedding@henryzhang.net?subject=来自${name}的祝福信息&body=${message}`
                )
              }
              variant="outlined"
              color="inherit"
              style={{ height: "28px" }}
            >
              电邮发送
            </Button>
          ),
        });
      }
    }
  }, [networkError, isInWechatFlag, name, message]);

  useEffect(() => {
    if (uploaded)
      enqueueSnackbar("祝福信息已发送，谢谢您。", {
        variant: "success",
        autoHideDuration: 3000,
      });
  }, [uploaded]);

  return {
    control,
    methods,
    networkError,
    onFormSubmit,
    errors,
    uploading,
    uploaded
  };
}

export default useMessageForm;
