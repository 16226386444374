import { Alert, Button, Container, Grid } from "@mui/material";
import styles from "./app.module.scss";
import Audio from "../components/music/music";
import { styled } from "@mui/material/styles";

import firstImage from "../assets/images/xi-double-light.jpg";
import secondImage from "../assets/images/cityhall-hug.jpg";
import thirdImage from "../assets/images/clerk-inner.jpg";
import fourthImage from "../assets/images/clerk-outside.jpg";
import fifthImage from "../assets/images/high-line.jpg";
import sixthImage from "../assets/images/cityhall-park-bench.jpg";
import seventhImage from "../assets/images/cafe-xy-take-pic-of-cz.jpg";
import eighthImage from "../assets/images/xi-standing-light.jpg";
import ninthImage from "../assets/images/xi-double-squat.jpg";
import tenthImage from "../assets/images/xi-double-dragon.jpg";
import eleventhImage from "../assets/images/xi-double-each.jpg";
import twelvethImage from "../assets/images/xi-double-m-s-f.jpg";
import thirteenImage from "../assets/images/xi-double-standing-flower.jpg";
import fourteenImage from "../assets/images/cityhall-park-look-back-and-smile.jpg";
import _15Image from "../assets/images/cityhall-park-look-each-other.jpg";
import _16Image from "../assets/images/cityhall-park-bench-diamond.jpg";
import _17Image from "../assets/images/cityhall-park-kiss.jpg";
import _18Image from "../assets/images/xi-double-sit.jpg";

import weddingInvite from "../assets/images/wedding_invite.png";
import liangchen from "../assets/images/liangchen.png";
import qiemu from "../assets/images/qiemu.png";
import yilutongxing from "../assets/images/yilutongxing.png";
import welcometoourwedding from "../assets/images/welcometoourwedding.png";
import MapContainer from "../components/map/map";
import IntegratedMessage from "../components/message";
import { useEffect, useRef, useState } from "react";
import useVisibilityChecker from "../hooks/useVisibilityChecker";
import { useSnackbar } from "notistack";

const Div = styled("div")(({ theme }) => ({
  fontSize: "13px",
  fontFamily: "Noto Sans",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  lineHeight: "29.89px",
}));

function App() {
  const [seen, setSeen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dateTextRef = useRef();

  const isDateTextVisible = useVisibilityChecker(dateTextRef);

  useEffect(() => {
    if (isDateTextVisible && seen === false) {
      enqueueSnackbar("注意，时间变化！", {
        variant: "warning",
        autoHideDuration: null,
        action: (key) => {
          return (
            <Button
              onClick={() => closeSnackbar(key)}
              variant="outlined"
              color="inherit"
              style={{ height: "28px" }}
            >
              我知道啦
            </Button>
          );
        },
      });
      setSeen(true);
    }
  }, [isDateTextVisible, seen]);

  return (
    <Container className={styles.app_container} maxWidth="sm">
      <div className={styles.inner_text_line_1}>
        <div>山野千里</div>
        <div>你是我藏在风中的欢喜</div>
      </div>
      <div>
        <img src={firstImage} alt="no1" width="100%" />
      </div>
      <div>
        <img src={weddingInvite} alt="wedding-invite" width="100%" />
      </div>
      <div className={styles.inner_text_line_2}>
        <Div>Hello!</Div>
        <Div>这是一封送给你的婚礼邀请函</Div>
        <br />
        <Div>打开链接的你，难道是上班时间在摸鱼~</Div>
        <Div>哈哈，既然来了，那就休息两分钟</Div>
        <Div>听听歌，继续往下看……</Div>
      </div>
      <Audio />
      <div className={styles.inner_text_line_3}>
        <Div>当你看到这份邀请函的时候</Div>
        <Div>说明我们筹备已久的婚礼</Div>
        <Div>进入倒计时啦</Div>
        <Div>很开心这一天</Div>
        <Div>你为我们而来</Div>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <img src={secondImage} alt="no2" width="100%" />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <img src={liangchen} alt="liang-chen" width="100%" />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <img src={thirdImage} alt="no3" width="49%" />
        <img src={fourthImage} alt="no4" width="49%" />
      </div>
      <div className={styles.inner_text_line_4}>
        <Div>在很小很小的概率下相识相爱</Div>
        <Div>是一件很美妙的事</Div>
      </div>
      <div>
        <img src={fifthImage} alt="no5" width="100%" />
      </div>
      <Grid
        container
        spacing={2}
        style={{
          maxHeight: "min-content",
          overflowY: "hidden",
          marginBottom: 20,
        }}
      >
        <Grid item xs={4}>
          <img src={sixthImage} alt="no6" width="100%" height="auto" />
        </Grid>
        <Grid item xs={8} style={{ alignSelf: "flex-end" }}>
          <img src={seventhImage} alt="no7" width="100%" height="auto" />
        </Grid>
      </Grid>
      <div>
        <img src={qiemu} alt="qiemu" width="100%" />
      </div>
      <div>
        <img src={eighthImage} alt="no8" width="100%" height="100%" />
      </div>
      <div className={styles.inner_text_line_5}>
        <Div>岁月悠长，山河无恙</Div>
        <Div>一腔孤勇和余生，都给你</Div>
      </div>
      <div>
        <img src={ninthImage} alt="no9" width="100%" height="100%" />
        <img src={tenthImage} alt="no10" width="100%" height="100%" />
      </div>
      <div className={styles.inner_text_line_6}>
        <Div>一起看星河滚烫</Div>
        <Div>一起看风月浪漫</Div>
      </div>
      <div>
        <img src={eleventhImage} alt="no11" width="100%" height="100%" />
      </div>
      <div className={styles.inner_text_line_7}>
        <Div>∞</Div>
        <Div>一遇倾心，一念永恒</Div>
      </div>
      <div>
        <img src={twelvethImage} alt="no12" width="100%" height="100%" />
      </div>
      <div className={styles.inner_text_line_8}>
        <Div>波澜不惊的生活，突然遇到你</Div>
        <Div>从此我的生活充满了惊喜与期待</Div>
      </div>
      <div>
        <img src={thirteenImage} alt="no13" width="100%" height="100%" />
      </div>
      <div>
        <img
          src={welcometoourwedding}
          alt="welcome-to-our-wedding"
          width="100%"
        />
      </div>
      <div>
        <img src={fourteenImage} alt="no14" width="100%" height="100%" />
      </div>
      <div className={styles.inner_text_line_9}>
        <Div>我们决定</Div>
        <Div>陪伴彼此</Div>
        <Div>一起走完余下人生</Div>
        <Div>从心动到暮首直至尽头</Div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <img src={_15Image} alt="no15" width="49%" />
        <img src={_16Image} alt="no16" width="49%" />
      </div>
      <div className={styles.inner_text_line_10}>
        <Div>今日所邀</Div>
        <Div>皆为生命在不同的经纬</Div>
        <Div>遇见的最重要的你们</Div>
      </div>
      <div>
        <img src={yilutongxing} alt="yi-lu-tong-xing" width="100%" />
      </div>
      <div className={styles.inner_text_line_10}>
        <Div>有生之年 欣喜相逢</Div>
        <Div>佳期已定 敬备喜筵</Div>
      </div>
      <div style={{ textAlign: "center" }}>
        <div>
          <img src={_17Image} alt="no17" width="100%" height="100%" />
        </div>
      </div>
      <div className={styles.inner_text_line_10}>
        <Div>婚礼</Div>
        <Div>时间 & 地点</Div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          textAlign: "center",
          marginBottom: "60px",
        }}
      >
        <div>
          <Div>2024年5月16日 星期四</Div>
          <Div>农历四月初九</Div>
          <Alert severity="warning" ref={dateTextRef} sx={{ padding: "0 8px" }}>
            <b>晚 · 五点零八分</b>
          </Alert>
        </div>
        <div>
          <Div>上海市静安区汶水路40号</Div>
          <Div>格乐利雅婚礼艺术中心</Div>
          <Div
            style={{
              display: "flex",
              height: "36.02px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            水上 · 汴京
          </Div>
        </div>
      </div>
      <MapContainer />
      <div className={styles.inner_text_line_10}>
        <Div>那我们就</Div>
      </div>

      <Div style={{ textAlign: "center" }}>赴一场浪漫的约会</Div>

      <div className={styles.inner_text_line_10}>
        <Div>❥.婚礼有专业摄影，欢迎大家积极与我们合影哦~</Div>
        <Div>❥.婚礼忙碌，如若我们招待不周，请多多包涵~</Div>
      </div>

      <div>
        <img src={_18Image} alt="no18" width="100%" height="100%" />
      </div>

      <div
        className={styles.inner_text_line_10}
        style={{ paddingBottom: "120px" }}
      >
        <Div>这一天</Div>
        <Div>感谢有您，陪我们启程</Div>
        <br />
        <Div>期待相聚</Div>
        <Div>祝万事顺遂</Div>
        <br />
        <br />
        <Div>— THANKS —</Div>
        <IntegratedMessage />
      </div>
    </Container>
  );
}

export default App;
