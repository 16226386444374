import "react-h5-audio-player/lib/styles.css";
import styles from "./music.module.scss";
import "./music.scss";

import React, { forwardRef, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import men from "../../assets/music/men.mp3";
import { Box, Fab, IconButton, Paper, Zoom } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import useVisibilityChecker from "../../hooks/useVisibilityChecker";
import { useTheme } from "@mui/material/styles";

function Audio() {
  const theme = useTheme();
  const player = useRef();
  const audioRef = useRef();
  const isVisible = useVisibilityChecker(audioRef);

  const [isPlaying, setIsPlaying] = React.useState(false);

  const play = () => {
    player.current.audio.current.play();
    setIsPlaying(true);
  };

  const pause = () => {
    player.current.audio.current.pause();
    setIsPlaying(false);
  };

  const playControl = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <>
      <Paper
        ref={audioRef}
        elevation={4}
        className={styles.audio_player_outer_container}
      >
        <div className={styles.audio_player_container}>
          <div className={styles.audio_player_container_left}>
            <div className={styles.music_title}>门 - 邓见超</div>
          </div>
          <div className={styles.audio_player_container_right}>
            <IconButton onClick={playControl}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </div>
        </div>
        <Box sx={{ width: "100%", marginTop: "8px" }}>
          <AudioPlayer
            onPlay={() => setIsPlaying(true)}
            progress
            preload="meta"
            src={men}
            autoPlay
            ref={player}
            loop // use this so no need to adjust state when finish playing music
          />
        </Box>
      </Paper>

      <Zoom
        key="primary"
        timeout={transitionDuration}
        in={isVisible === false}
        style={{
          transitionDelay: `${
            isVisible === false ? transitionDuration.exit : 0
          }ms`,
        }}
        unmountOnExit
      >
        <div className={styles.floating_play_button}>
          <Fab size="small" onClick={playControl} color="error" aria-label="h5-music-player-play-pause">
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </Fab>
        </div>
      </Zoom>
    </>
  );
}

export default forwardRef(Audio);
