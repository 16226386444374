import axios from "axios";

const url =
  "https://rocketchat.henryzhang.net/hooks/661ece728b53f50ff974863d/8ZphLDGz25qEofcBZEKq4dW75w82fgyrivhCScKMefiKNxaH";

export const messageService = (name, message) => {
  return axios.post(
    url,
    {
      text: `${name} - ${message}`,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
